import type { NextRouter } from 'next/router'
import { useRouter } from '@/parts/router/query-preserving'
import { createContentActions } from '@blakeelearning/content-loader-render'
import { indexContentSpec } from '@blakeelearning/content-specs-mathseeds-prime'
import { useUiState } from '../loading/hook'
import type { UiContextValue } from '../loading/context'
import { Logger, useLogger } from '../logger/hook'
import { useLogoutAction } from '../logout/action'

export function useActions() {
  return useActionsWith(useRouter(), useUiState(), useLogger(), useLogoutAction())
}

export function useActionsWith(
  { push }: NextRouter,
  { setUiToReady }: UiContextValue,
  { log }: Logger,
  logout: () => void,
) {
  return createContentActions(indexContentSpec, {
    goToGames: () => push('/games'),
    goToLessonTrees: () => push('/lessons'),
    goToShop: () => push('/shop'),
    goToMyProgress: () => push('/progress'),
    logOut: () => logout(),
    goToStadium: () => push('/stadium'),
    goToMentalMinute: () => push('/mental-minute'),
    goToMySeedbobs: () => push('/seedbob'),
    goToLeaderboards: () => push('/leaderboard'),
    goToMemoryBank: () => push('/memory-bank'),
    unhandledError: (error: unknown) => log('UNHANDLED ERROR', error),
    readyForUserInput: setUiToReady,
  })
}
