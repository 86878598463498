import React from 'react'
import DebuggableInteractive from '@/parts/debug/interactive/component'
import { useConfiguration } from './configuration'

export type MainNavigationVariables = {
  studentData: {
    studentName: string
    coinCount: number
    selectedWoodling: string
  }
}

export default function MainNavigationInteractive() {
  return <DebuggableInteractive configuration={useConfiguration()} />
}
