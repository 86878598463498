import type { createContentActions, Manifest } from '@blakeelearning/content-loader-render'
import { useStudent } from '@/parts/student/hook'
import { getAccent } from '@/parts/accent/hook'
import { useRef } from 'react'
import { useActions } from './actions'
import { getDisplayName } from '@/utils/student-name'

export function useConfiguration() {
  return useConfigurationWith(useActions())
}

export function useConfigurationWith({ contentActions, contentSpec }: ReturnType<typeof createContentActions>) {
  const student = useStudent()
  const accent = getAccent(student)
  const studentName = getDisplayName(student)
  const {
    rewardTotals: { coins: coinCount },
    selectedWoodling,
  } = student

  const variables = {
    studentData: {
      studentName,
      coinCount,
      selectedWoodling,
    },
  }

  return useRef({
    accent,
    manifests: [['index', 'main-nav']] as Manifest[],
    contentActions,
    contentSpec,
    variables,
  }).current
}
