import MainNavigationInteractive from '@/parts/main-navigation/interactive'
import Head from 'next/head'

export default function Home() {
  return (
    <>
      <Head>
        <title>Home | Mathseeds Prime</title>
      </Head>
      <MainNavigationInteractive />
    </>
  )
}
