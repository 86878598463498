import type { Student } from '@/graphql/types'

export function getDisplayName(
  { firstName: rawFirstName, lastName: rawLastName }: Student,
  fallback = 'Student',
): string {
  const firstName = rawFirstName?.trim()
  const lastName = rawLastName?.trim()

  if (!firstName) return fallback
  if (!lastName) return firstName
  return `${firstName} ${lastName.charAt(0)}.`
}
