import { useConfiguration } from '../configuration/wrapper'

export function useLogoutAction() {
  const { logoutUrl } = useConfiguration()
  return () => doLogoutWith(logoutUrl)
}

export function doLogoutWith(logoutUrl: string) {
  const form = document.createElement('form')
  form.setAttribute('method', 'post')
  form.setAttribute('action', logoutUrl)
  document.body.appendChild(form)
  form.submit()
}
